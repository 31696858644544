import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import SectionHeader from './sectionHeader'
import P from './paragraph'
import { device } from '../styles/media-queries'

const teamData = {
  name: 'Leadership Team',
  members: [
    {
      name: 'Arun Sood',
      title: 'Founder',
      // description:
      //   'Dr. Arun Sood is the Founder and the CEO of SCIT Labs Inc.  He is sole inventor or co-inventor of all six SCIT technology patents that are based on the research undertaken in his research center.  He leads a strong executive team.   His experience includes projects for process, chemical, manufacturing and financial services companies and government agencies. Dr Sood is Professor of Computer Science and Director, International Cyber Center at George Mason University. He has more than 180 publications, 7 patents and is frequently invited to speak about proactive approaches to cyber security. He has organized and led workshops on Cyber Security and Global Affairs at Oxford, Zurich, Budapest and Barcelona; CERT capacity building at Abuja, Nigeria and Nairobi, Kenya; and currently leads the Workshop Series on Cyber Resilience.  He has a BTech (EE)  from the Indian Institute of Technology, Delhi, and MS(EE) and PhD (EE) from Carnegie Mellon University.',
    },
    {
      name: 'Anantha Bangalore',
      title: 'Chief Architect',
    },
    {
      name: 'Kapil Sood',
      title: 'Vice President Business Development',
    },
  ],
  // {
  //   name: 'Arleen Zank',
  //   description:
  //     'Mr Bangalore is the Chief Architect of SCIT Labs Inc. He has more than 18 years of experience in design, analysis, and implementation of information systems architecture. Currently he is the Principal Architect of the public cloud initiative at DHS/ESDO. As part of this work he helped design and implement a secure and robust Platform as a service (PaaS). He also provides strategic guidance and thought leadership across multiple projects such as Cloud, Mobile, Business Intelligence, Security and Big Data at DHS/ESDO and other Federal government agencies. Prior to consulting at DHS, he helped design the system architecture for a publicly accessible web-based healthcare career platform that includes assessment, learning, and career management tools, whose primary purpose is to assist its users to enter into programs leading to the credential they need to begin or advance in a healthcare-related career. This project was funded by the Dept. of Labor.  He has been instrumental in the design and development of all of the SCIT products such as SCIT-ecommerce, SCIT-DNS, etc. He has also worked as a consultant at the National Library of Medicine. He was involved in the design and development of systems in the domain of Medical Informatics. He helped develop algorithms to improve search and retrieval of bio-medical information.',
  // },
  // {
  //   name: 'Lee Mericle',
  //   description:
  //     'Ms. Mericle is Chief Software Engineer.  Lee has broad experience in software design and development and in formal Government testing management. She has worked with systems ranging from large computer-based training (CBT) systems to a cellular telephone antenna system. She works on various research projects for the National Library of Medicine’s (NLM) Lister Hill National Center for Biomedical Communications (LHNCBC) and served as Aquilent’s program manager for NLM projects',
  // },
  // {
  //   name: 'Robert Banks',
  //   description:
  //     'COL Banks (Ret) is Director - Military Applications with more than 37 years of combined active/reserve duty and industry product development experience. Commander of the Army’s largest Aviation Battalion, he retired as the Deputy Director Current Operations USCYBERCOM, and while on active dutywas Chief of Operations, Army Global Network Operations & Security Center, Defense Industrial Base, Acquisition Officer, Asymmetric Warfare Office for Electronic Warfare and Program Manager, Army National Guard Bureau, where his published War College research developed new information technology strategies for the 54 Army National Guard Joint Forces Headquarters’ WAN Modernization. He worked at Alcatel for over 15 years leading many Lean Six Sigma efforts as a Customer Support Manager, Network and New Product Engineer of backbone Lightwave, Microwave Radio and Cross-Connect Networks Products.',
  // },
}

const advisorData = {
  name: 'Advisors',
  members: [
    {
      name: 'B.G. "Buddy" Beck',
      title: 'Industrial Entrepreneur',
    },
    {
      name: 'General Michael Hayden',
      title: 'Ex-Director, NSA, CIA',
    },
    {
      name: 'Arjun Malhorta',
      title: 'Ex-Chairman & CEO, Headstrong (sold in 2011)',
    },
    {
      name: 'Roger Thornton',
      title: 'Founder, Fortify & Alien Vault',
    },
    {
      name: 'Stuart Malawer',
      title: 'Counsel',
    },
  ],
}

const Team = styled.div`
  max-width: 720px;
  margin: 2rem auto;
`

const Member = styled.div`
  // display: flex;
  // > *:nth-child(1) {
  //   width: 25%;
  // }
  // > *:nth-child(2) {
  //   width: 75%;
  // }
`

const ImageWrap = styled.div`
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-right: 2rem;
`

const CopyWrap = styled.div`
  text-align: center;
`

const Name = styled.h3`
  margin: 0;
`

const Title = styled.h4`
  margin: 0;
  font-weight: 400;
`

const Members = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > * {
      flex: 0 0 50%;
    }
  }
`

const Teams = styled.div``

const TeamComponent = ({ name, members }) => (
  <Team>
    <SectionHeader style={{ textAlign: 'center' }}>{name}</SectionHeader>
    <Members>
      {members.map(i => (
        <Member key={i.name}>
          {/* <ImageWrap src={asoodImg} /> */}
          <CopyWrap>
            <Name>{i.name}</Name>
            <Title>{i.title}</Title>
            <P>{i.description}</P>
          </CopyWrap>
        </Member>
      ))}
    </Members>
  </Team>
)

const TeamsComponent = () => (
  <Teams>
    {[teamData, advisorData].map(team => (
      <TeamComponent {...team} key={team.name} />
    ))}
  </Teams>
)

TeamComponent.PropTypes = {
  members: PropTypes.node.isRequired,
  name: PropTypes.string,
}

export default TeamsComponent

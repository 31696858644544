import React from 'react'
import Link from './link'
import styled from 'react-emotion'
import { blue, black } from '../styles/colors'
import { device } from '../styles/media-queries'
import SCITLogo from '../images/svg/scit-logo-new.svg'

import { outerPadding } from '../styles/vars'
const navItems = [
  {
    name: 'About Us',
    path: '/about-us/',
  },
  {
    name: 'Products & Solutions',
    path: '/products/',
  },
  // {
  //   name: 'News',
  //   path: '/news/',
  // },

  {
    name: 'Publications',
    path: '/publications/',
  },
  {
    name: 'Projects',
    path: '/projects/',
  },
  {
    name: 'Contact Us',
    path: '/contact/',
  },
]

const NavItem = styled.li`
  margin-bottom: 0;
  font-size: 1.6rem;
  @media ${device.laptop} {
    font-size: 1rem;
  }
`

const Nav = styled.nav`
  display: none;
  color: ${black};
  &.show-mobile-menu {
    display: block;
    position: fixed;
    min-height: 100vh;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 2;
    min-width: 100vw;
    padding-top: 5rem;
    & .top-level-nav {
      flex-direction: column;
      align-items: center;
    }
  }
  @media ${device.laptop} {
    display: block;
  }
`

const NavCat = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;
  &.top-level-nav .sub-nav {
    display: none;
  }
  &.top-level-nav {
    display: flex;
    justify-content: space-around;
    position: relative;
  }
`

const NavWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${black};
  @media ${device.laptop} {
    padding: ${outerPadding};
    & > *:first-child {
      flex: 1 0 25%;
    }
    & > *:nth-child(2) {
      flex: 2 0 75%;
    }
  }
`

const MobileToggle = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.75rem;
  @media ${device.laptop} {
    display: none;
  }
`
const HamburgerBar = styled.div`
  height: 5px;
  width: 30px;
  background-color: ${blue};
  margin-bottom: 5px;
`

const SiteTitle = styled.h1`
  margin: 0;
`

const LogoWrap = styled.div`
  min-width: 9rem;
  max-width: 100%;
  display: inline-block;
  & img {
    margin: 0;
    display: block;
  }
`

const MobileClose = styled.div`
  display: block;
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.6rem;
  @media ${device.laptop} {
    display: none;
  }
`

const renderNavItem = item => {
  if (item.path) {
    return (
      <Link activeClassName="active" to={item.path}>
        {item.name}
      </Link>
    )
  }
  return (
    <div>
      {item.name} {item.subNav && <i className="chevron" />}
    </div>
  )
}

class NavComponent extends React.Component {
  state = {
    showMobileMenu: false,
  }

  toggleMobileMenu = () => {
    this.setState(({ showMobileMenu }) => ({
      showMobileMenu: !showMobileMenu,
    }))
  }

  render() {
    return (
      <NavWrapper {...this.props}>
        <SiteTitle>
          <Link to="/" className="logo-link">
            <LogoWrap>
              <SCITLogo />
            </LogoWrap>
          </Link>
        </SiteTitle>
        <Nav className={this.state.showMobileMenu && 'show-mobile-menu'}>
          <NavCat className="top-level-nav">
            {navItems.map(item => (
              <NavItem key={item.name}>{renderNavItem(item)}</NavItem>
            ))}
          </NavCat>
          <MobileClose onClick={this.toggleMobileMenu}>X</MobileClose>
        </Nav>
        <MobileToggle onClick={this.toggleMobileMenu}>
          <HamburgerBar />
          <HamburgerBar />
          <HamburgerBar />
        </MobileToggle>
      </NavWrapper>
    )
  }
}

export default NavComponent
